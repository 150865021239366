export default {
  path: '/',
  name: 'system',
  component: () => import('@/layouts/BaseLayout'),
  children: [
    {
      path: 'function_config',
      meta: { title: '功能配置' },
      component: () => import('@/views/functionConfig/index'),
    },
    {
      path: 'order_prefix',
      name: 'order_prefix',
      meta: { title: '单据字头', permission: 'order_prefix' },
      component: () => import('@/views/orderPrefix/OrderPrefix'),
    },
    {
      path: 'label_configs',
      name: 'label_configs',
      meta: { title: '标签配置', permission: 'label_configs' },
      component: () => import('@/views/labelConfigs/LabelConfigs'),
    },
    {
      path: 'label_configs',
      name: 'label_configs',
      meta: { title: '标签配置', permission: 'label_configs' },
      component: () => import('@/views/labelConfigs/LabelConfigs'),
    },
    {
      path: 'system_configs',
      name: 'system_configs',
      meta: { title: '标签配置', permission: 'system_configs' },
      component: () => import('@/views/systemConfigs/SystemConfigs'),
    },
    {
      path: "mobile_download",
      component: () => import("@/views/mobileDownload/index"),
    },
  ],
}