export default {
  path: "/stock_out",
  name: "stock_out",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/stock_out/delivery_order_create",
  children: [
    {
      path: "delivery_order_list",
      meta: { title: "出库通知单" },
      component: () => import("@/views/stockOut/deliveryOrderList/index"),
    },
    {
      path: "delivery_order_create",
      meta: { title: "出库通知单" },
      component: () => import("@/views/stockOut/deliveryOrderCreate/index"),
    },
    {
      path: "delivery_order_recreate",
      meta: { title: "出库通知单" },
      component: () => import("@/views/stockOut/deliveryOrderRecreate/index"),
    },
    // {
    //   path: 'stock_out/stock_out_order_detail',
    //   meta: { title: '客户单号详情' },
    //   component: () => import('@/views/stockOut/stockOutOrderDetail/index'),
    // },
    {
      path: "picking_task",
      meta: { title: "拣货任务" },
      component: () => import("@/views/stockOut/pickingTask/index"),
    },
    {
      path: "picking_task_detail",
      meta: { title: "拣货任务详情" },
      component: () => import("@/views/stockOut/pickingTaskDetail/index"),
    },
    {
      path: "picking_record_detail",
      meta: { title: "拣货记录详情" },
      component: () => import("@/views/stockOut/pickingRecordDetail/index"),
    },
    {
      path: "picking_record_create",
      meta: { title: "拣货新增" },
      component: () => import("@/views/stockOut/pickingRecordCreate/index"),
    },
    {
      path: "delivery_task",
      meta: { title: "复核任务" },
      component: () => import("@/views/stockOut/deliveryTask/index"),
    },
    {
      path: "delivery_record_create",
      meta: { title: "复核新增" },
      component: () => import("@/views/stockOut/deliveryRecordCreate/index"),
    },
    {
      path: "delivery_task_detail",
      meta: { title: "复核任务详情" },
      component: () => import("@/views/stockOut/deliveryTaskDetail/index"),
    },
    {
      path: "delivery_record_detail",
      meta: { title: "复核记录详情" },
      component: () => import("@/views/stockOut/deliveryRecordDetail/index"),
    },
    {
      path: "quick_delivery_record",
      meta: { title: "快速复核" },
      component: () => import("@/views/stockOut/quickDeliveryRecord/index"),
    },
    {
      path: "quick_picking_record",
      component: () => import("@/views/stockOut/quickPickingRecord/index"),
    },
    // {
    //   path: 'stock_out/delivery_order',
    //   meta: { title: '出库通知单' },
    //   component: () => import('@/views/stockOut/deliveryOrder/index'),
    // },
    // {
    //   path: 'stock_out/delivery_order_detail',
    //   meta: { title: '出库通知单详情' },
    //   component: () => import('@/views/stockOut/deliveryOrderDetail/index'),
    // },
  ],
};
