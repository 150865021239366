export default {
  path: '/',
  name: 'warehouse_layout',
  component: () => import('@/layouts/BaseLayout'),
  children: [
    {
      path: 'warehouse_layout',
      meta: { title: '库区平面图' },
      component: () => import('@/views/warehouseLayout'),
    },
  ],
}