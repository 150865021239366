export default {
  path: "/charge",
  component: () => import("@/layouts/BaseLayout"),
  children: [
    {
      path: "rule",
      component: () => import("@/views/charge/rule"),
    },
    {
      path: "expense",
      component: () => import("@/views/charge/expense"),
    },
    {
      path: "report",
      component: () => import("@/views/charge/report"),
    },
    {
      path: "detail",
      component: () => import("@/views/charge/detail"),
    },
  ],
};
