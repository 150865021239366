export default {
  path: '/',
  component: () => import('@/layouts/BaseLayout'),
  children: [
    {
      path: 'stock_in_report',
      meta: { title: '入库报表' },
      component: () => import('@/views/stockInReport'),
    },
    {
      path: 'stock_out_report',
      meta: { title: '出库报表' },
      component: () => import('@/views/stockOutReport'),
    },
    {
      path: 'delivery_record_report',
      meta: { title: '复核报表' },
      component: () => import('@/views/deliveryRecordReport'),
    },
  ],
}