export default {
  path: '/stock_check',
  name: 'stock_check',
  component: () => import('@/layouts/BaseLayout'),
  redirect: '/stock_check/stock_check_order_create',
  children: [
    {
      path: 'stock_check_order_create',
      meta: { title: '盘点单据', permission: 'stock_check_order' },
      component: () => import('@/views/stockCheck/stockCheckOrderCreate/index'),
    },
    {
      path: 'stock_check_order_detail',
      meta: { title: '盘点详情', permission: 'stock_check_order' },
      component: () => import('@/views/stockCheck/stockCheckOrderDetail/index'),
    },
    {
      path: 'stock_check_task',
      meta: { title: '盘点任务', permission: 'stock_check_record' },
      component: () => import('@/views/stockCheck/stockCheckTask/index'),
    },
    {
      path: 'stock_check_record_create',
      meta: { title: '盘点记录创建', permission: 'stock_check_record' },
      component: () => import('@/views/stockCheck/stockCheckRecordCreate/index'),
    },
    {
      path: 'stock_check_record_detail',
      meta: { title: '盘点记录详情', permission: 'stock_check_record' },
      component: () => import('@/views/stockCheck/stockCheckRecordDetail/index'),
    },
  ],
}