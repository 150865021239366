export default {
  path: '/',
  name: 'stock_transfer',
  component: () => import('@/layouts/BaseLayout'),
  children: [
    {
      path: 'stock_transfer/order_create',
      meta: { title: '调拨单据' },
      component: () => import('@/views/stockTransfer/orderCreate/index'),
    },
    {
      path: 'stock_transfer/order_record',
      meta: { title: '调拨明细' },
      component: () => import('@/views/stockTransfer/orderRecord/index'),
    },
    {
      path: 'stock_transfer/order_detail',
      meta: { title: '调拨详情' },
      component: () => import('@/views/stockTransfer/orderDetail/index'),
    },
    {
      path: 'location_transfer_record',
      meta: { title: '移库记录' },
      component: () => import('@/views/locationTransferRecord/index'),
    },
  ],
}