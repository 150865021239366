export default {
  path: '/charging',
  name: 'charging',
  component: () => import('@/layouts/BaseLayout'),
  redirect: '/charging/pay_service',
  children: [
    {
      path: 'pay_service',
      meta: { title: '收费项目', permission: 'pay_service' },
      component: () => import('@/views/charging/payService/index'),
    },
    {
      path: 'charge_records',
      meta: { title: '其他费用', permission: 'charge_records' },
      component: () => import('@/views/charging/chargeRecords/index'),
    },
    {
      path: 'stock_in_and_out',
      meta: { title: '出入库记录', permission: 'stock_in_and_out' },
      component: () => import('@/views/charging/stockInAndOut/index'),
    },
    {
      path: 'charge_configuration',
      meta: { title: '计费配置', permission: 'charge_configuration' },
      component: () => import('@/views/charging/chargeConfiguration/index'),
    },
    {
      path: 'transport',
      meta: { title: '运输费用', permission: 'transport' },
      component: () => import('@/views/charging/transport/index'),
    },
    {
      path: 'handling',
      meta: { title: '装卸费用', permission: 'handling' },
      component: () => import('@/views/charging/handling/index'),
    },
    {
      path: 'warehouse_storage',
      meta: { title: '仓储费用', permission: 'warehouse_storage' },
      component: () => import('@/views/charging/warehouseStorage/index'),
    },
    {
      path: 'charge_sum',
      meta: { title: '费用合计', permission: 'charge_sum' },
      component: () => import('@/views/charging/chargeSum/index'),
    },
    {
      path: 'storage_records',
      meta: { title: '仓储记录', permission: 'storage_records' },
      component: () => import('@/views/charging/storageRecords/index'),
    },
  ],
}