export default {
  path: '/',
  component: () => import('@/layouts/BaseLayout'),
  redirect: '/',
  children: [
    {
      path: 'warehouse_kanban',
      component: () => import('@/views/warehouseKanban'),
    },
    {
      path: 'receipt_kanban',
      component: () => import('@/views/receiptKanban'),
    },
    {
      path: 'picking_kanban',
      component: () => import('@/views/pickingKanban'),
    },
  ],
}