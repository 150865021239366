export default {
  path: '/',
  name: 'manage',
  component: () => import('@/layouts/BaseLayout'),
  children: [
    {
      path: 'warehouse',
      name: 'warehouse',
      meta: { title: '仓库管理', permission: 'warehouse' },
      component: () => import('@/views/warehouse/Warehouse'),
    },
    {
      path: 'reservoir_area',
      name: 'reservoir_area',
      meta: { title: '库区管理', permission: 'reservoir_area' },
      component: () => import('@/views/reservoirArea/index'),
    },
    {
      path: 'stock_location',
      name: 'stock_location',
      meta: { title: '库位管理', permission: 'location' },
      component: () => import('@/views/stockLocation/StockLocation'),
    },
    {
      path: 'client',
      name: 'client',
      meta: { title: '客户管理', permission: 'client' },
      component: () => import('@/views/client/index'),
    },
    {
      path: 'unit',
      name: 'unit',
      meta: { title: '单位管理', permission: 'unit' },
      component: () => import('@/views/unit/Unit'),
    },
    {
      path: 'order_type',
      name: 'order_type',
      meta: { title: '单位管理', permission: 'order_type' },
      component: () => import('@/views/orderType/OrderType'),
    },
    {
      path: 'material',
      name: 'material',
      meta: { title: '产品信息', permission: 'material' },
      component: () => import('@/views/material/Material'),
    },
    {
      path: 'categories',
      name: 'categories',
      meta: { title: '产品信息', permission: 'category' },
      component: () => import('@/views/categories/Categories'),
    },
    
    {
      path: 'material_batch',
      name: 'material_batch',
      meta: { title: '产品批次', permission: 'batch' },
      component: () => import('@/views/materialBatch/MaterialBatch'),
    },
    {
      path: 'expiration_warning',
      name: 'expiration_warning',
      meta: { title: '临期预警', permission: 'expiration_warning' },
      component: () => import('@/views/expirationWarning/index'),
    },
    {
      path: 'client_product_list',
      component: () => import('@/views/clientProductList/index'),
    },
  ],
}