export default {
  path: "/stock_in",
  name: "stock_in",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/stock_in/receipt_order_list",
  children: [
    {
      path: "receipt_order_list",
      meta: { title: "入库通知单", permission: "receipt_order" },
      component: () => import("@/views/stockIn/receiptOrderList/index"),
    },
    {
      path: "receipt_order_create",
      meta: { title: "入库通知单", permission: "receipt_order" },
      component: () => import("@/views/stockIn/receiptOrderCreate/index"),
    },
    {
      path: "receipt_order_detail",
      meta: { title: "入库通知单详情", permission: "receipt_order" },
      component: () => import("@/views/stockIn/receiptOrderDetail/index"),
    },
    {
      path: "receipt_task",
      meta: { title: "收货任务", permission: "receipt_record" },
      component: () => import("@/views/stockIn/receiptTask/index"),
    },
    {
      path: "receipt_record_create",
      meta: { title: "收货记录", permission: "receipt_record" },
      component: () => import("@/views/stockIn/receiptRecordCreate/index"),
    },
    {
      path: "quick_receipt_record",
      meta: { title: "快速收货", permission: "receipt_record" },
      component: () => import("@/views/stockIn/quickReceiptRecord/index"),
    },
    {
      path: "receipt_record_detail",
      meta: { title: "收货记录详情", permission: "receipt_record" },
      component: () => import("@/views/stockIn/receiptRecordDetail/index"),
    },
    // {
    //   path: 'check_task',
    //   meta: { title: '质检任务', permission: 'check_record' },
    //   component: () => import('@/views/stockIn/checkTask/index'),
    // },
    // {
    //   path: 'check_record_create',
    //   meta: { title: '质检记录', permission: 'check_record' },
    //   component: () => import('@/views/stockIn/checkRecordCreate/index'),
    // },
    // {
    //   path: 'check_record_detail',
    //   meta: { title: '质检记录详情', permission: 'check_record' },
    //   component: () => import('@/views/stockIn/checkRecordDetail/index'),
    // },
    {
      path: "shelve_record_create",
      component: () => import("@/views/stockIn/shelveRecordCreate/index"),
    },
    {
      path: "shelve_order_detail",
      component: () => import("@/views/stockIn/shelveOrderDetail/index"),
    },
    {
      path: "shelve_record_detail",
      component: () => import("@/views/stockIn/shelveRecordDetail/index"),
    },
    {
      path: "shelve_task",
      component: () => import("@/views/stockIn/shelveTask/index"),
    },
  ],
};
