export default {
  path: "/",
  name: "inventory",
  component: () => import("@/layouts/BaseLayout"),
  children: [
    {
      path: "inventory",
      component: () => import("@/views/inventory/Inventory"),
    },
    {
      path: "inventory_report",
      component: () => import("@/views/inventoryReport/InventoryReport"),
    },
    {
      path: "batch_inventory_report",
      component: () => import("@/views/batchInventoryReport"),
    },
    {
      path: "material_inventory_report",
      component: () => import("@/views/materialInventoryReport"),
    },
    {
      path: "location_flow",
      component: () => import("@/views/locationFlow"),
    },
    {
      path: "inventory_flow",
      component: () => import("@/views/inventoryFlow/index"),
    },
  ],
};
